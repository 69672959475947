import Decimal from "decimal.js";
import type { ModelRef } from "vue";

export function useNumberInput(
    model: ModelRef<number | string> | Ref<number | string>,
    el: Ref<HTMLElement | undefined>,
    options: {
        max?: number;
        min?: number;
        decimal?: number;
    },
    formatter: Function
) {
    const amount = ref(0);
    const focused = ref(false);
    const input = ref<HTMLInputElement | undefined>();

    const onFocus = () => {
        if (!el.value) return;
        focused.value = true;
    };

    const onBlur = () => {
        if (!el.value) return;

        const max = options.max ?? 0;
        const min = options.min ?? 0;

        if (isNaN(parseFloat(model.value.toString()))) {
            model.value = options.min ?? 0;
        }

        let value = parseFloat(model.value.toString().replace(/,/g, ""));

        if (value < min) {
            model.value = min;
        }

        if (max !== 0 && value > max) {
            model.value = max;
        }

        focused.value = false;
    };

    const onInput = (e: Event) => {
        let value = (e.target as HTMLInputElement).value;
        if (value === "" || !value.length) return;

        // Remove all non-numeric characters
        value = value.replace(/[^0-9.]/g, "");

        // Authorize only decimal places
        if (options.decimal) {
            value = value.replace(
                new RegExp(`(\\.\\d{${options.decimal}})\\d+`),
                "$1"
            );
        } else {
            value = value.replace(/(\.\d\d)\d+/, "$1");
        }

        if (!value) {
            model.value = options.min ?? 0;
            return;
        }

        let val = new Decimal(value)
            .toDecimalPlaces(options.decimal ?? 2)
            .toNumber();

        model.value = val;
    };

    const onKeyPress = (e: KeyboardEvent) => {
        if (!/[\d.,]/.test(e.key)) {
            e.preventDefault();
        }
        if (e.key === "," || e.key === ".") {
            if (
                (e.target as HTMLInputElement).value.includes(",") ||
                (e.target as HTMLInputElement).value.includes(".")
            ) {
                e.preventDefault();
            }
        }
    };

    const content = computed({
        get: () => {
            if (focused.value) {
                return new Decimal(model.value ?? 0)
                    .toDecimalPlaces(options.decimal ?? 0)
                    .toNumber();
            }

            return formatter ? formatter(model.value ?? 0) : model.value;
        },
        set: value => {
            // model.value = parseFloat(value);
        }
    });

    const register = (el: HTMLElement) => {
        if (!el) return;

        el.addEventListener("focus", onFocus);
        el.addEventListener("blur", onBlur);
        el.addEventListener("input", onInput);
        el.addEventListener("keypress", onKeyPress);
    };

    const unregister = (el: HTMLElement) => {
        if (!el) return;

        el.removeEventListener("focus", onFocus);
        el.removeEventListener("blur", onBlur);
        el.removeEventListener("input", onInput);
        el.removeEventListener("keypress", onKeyPress);
    };

    // onMounted(() => () => {
    //     register(el.value!);
    // });

    onUnmounted(() => {
        unregister(input.value!);
    });

    watch(
        el,
        (val: any) => {
            if (!val) return;

            if (val.input === undefined) {
                input.value = val;
            } else {
                input.value = val.input;
            }
        },
        { immediate: true, deep: true }
    );

    watch(input, (val: any) => {
        if (!val) return;

        register(val);
    });

    return {
        amount,
        content
    };
}
